#stripe-checkout {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  form {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
    .card-row {
      max-width: 600px;
      width: 100%;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        justify-content: flex-start;
      }
      .StripeElement {
        padding: 15px;
      }
      label {
        flex: 1;
        color: #fff;
        margin: 0px 10px;
        .card-holder-name {
          width: 100%;
        }
        .zip-code {
          width: 100%;
        }
        input {
          font-family: Muli;
          color: #ffffff;
          letter-spacing: 0.025em;
          font-size: 18px;
          &::placeholder {
            color: #7b7b7b;
          }
        }
        &.card-number {
          position: relative;
          svg {
            width: 20px;
            height: 20px;
            position: absolute;
            bottom: 32px;
            right: 10px;
            color: #aaaaaa;
            fill: #aaaaaa;
          }
        }
      }
    }
  }
  .DemoPickerWrapper {
    padding: 0 12px;
    font-family: "Source Code Pro", monospace;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background: white;
    margin: 24px 0 48px;
    width: 100%;
  }

  .DemoPicker {
    font-size: 18px;
    border-radius: 3px;
    background-color: white;
    height: 48px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    border: 0;
    width: 100%;
    color: #6772e5;
    outline: none;
    background: transparent;

    -webkit-appearance: none;
  }

  .DemoWrapper {
    margin: 0 auto;
    max-width: 500px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }

  form {
    button {
      white-space: nowrap;
      border: 0;
      outline: 0;
      display: inline-block;
      height: 40px;
      line-height: 40px;
      padding: 0 14px;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      background-color: #6772e5;
      text-decoration: none;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
      margin-top: 10px;
    }

    button:hover {
      color: #fff;
      cursor: pointer;
      background-color: #7795f8;
      transform: translateY(-1px);
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    }
  }

  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    padding: 10px 14px;
    font-size: 1em;
    font-family: Muli;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-bottom: 1px dashed $radivision-blue;
    outline: 0;
    background-color: transparent;
    background: transparent;
    border-radius: 0;
    color: #ffffff;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .promotion-terms-modal {
    width: 700px;
    max-width: 800px !important;
    .promotion-terms-modal-inner {
      padding: 20px;
      p,
      li,
      h4 {
        text-align: left;
        color: #ffffff;
        font-family: Muli;
        font-size: 16px;
        margin: 15px 0px;
      }
      li {
        margin-left: 20px;
        list-style: disc;
      }
      h4 {
        font-size: 18px;
        margin-top: 30px;
      }
      a {
        color: $radivision-blue;
        cursor: pointer;
      }
    }
  }
  .terms-and-conditions-link {
    color: $radivision-blue;
    text-decoration: underline;
    cursor: pointer;
  }
  @media (max-width: 500px) {
    form {
      .card-row {
        flex-wrap: wrap;
        label {
          width: 100%;
          min-width: 90%;
          .StripeElement--empty {
            padding: 15px;
          }
        }
      }
    }
  }
}
#payment-success-modal,
#change-subscription-modal {
  .modal-header {
    p {
      text-align: center;
      flex-grow: 1;
    }
  }
  .modal-body {
    padding-top: 10px !important;
  }
  .payment-success-inner {
    h1 {
      font-size: 20px;
      text-align: center;
    }
    h3 {
      font-size: 18px;
      text-align: center;
    }
  }
}
.scholarship-coupon-status {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-start;
  margin-top: 10px;
  .status-icon {
    width: 30px;
    max-height: 30px;
    svg {
      fill: $radivision-blue;
    }
  }
  .status-msg {
    margin-left: 10px;
    h4 {
      font-family: Muli;
      color: $radivision-blue;
      font-size: 17px;
    }
    p {
      font-family: Muli;
      font-size: 15px;
      text-align: left !important;
      &.invalid-code {
        color: #d00;
      }
    }
  }
}
#promote-pitch-modal {
  #stripe-checkout label {
    span,
    div {
      color: white;
    }
  }
}
