.post-preview {
  position: relative;
  min-height: 60px;
  overflow: hidden;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);

  &.photo-preview {
    margin: 10px 0px 0px 0px;
    border-radius: 20px 20px 0px 0px;
    max-height: 460px;
    overflow-y: auto;

    img {
      width: 100%;
    }
  }

  &.link-preview {
    margin: 0px 0px 10px 0px;
    border-radius: 20px 20px 20px 20px;
  }

  &.photo-preview~.link-preview {
    border-radius: 0px 0px 20px 20px;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 1;

    svg {
      fill: #aaaaaa;
    }
  }

  .preview-error {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: #aaaaaa;
    min-height: 60px;

    i {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      border: 2px solid #aaaaaa;
      color: #aaaaaa;
      font-size: 20px;
      font-style: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 10px;
    }
  }

  .preview-title {
    min-height: 50px;
    padding: 5px 50px 0px 10px;

    h3 {
      margin: 0px;
      padding: 0px;
      font-size: 16px;
      color: #171717;
    }

    a {
      display: block;
      position: relative;
      font-size: 16px;
      color: #888888;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;

      &::after {
        display: block;
        content: "";
        position: absolute;
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        height: 100%;
        top: 0px;
        bottom: 0px;
        right: 0;
        width: 40%;
      }
    }
  }

  .dots {
    display: flex;
    justify-content: center;
    align-content: center;
    min-height: 60px;

    div {
      width: 7px;
      height: 7px;
      align-self: center;
      border: 0px solid rgba(0, 0, 0, 0.7);
      background-color: rgba(0, 0, 0, 0.5);

      border-radius: 50%;
      float: left;
      margin: 0 5px;
      -webkit-transform: scale(0);
      transform: scale(0);
      -webkit-animation: fx 1000ms ease infinite 0ms;
      animation: fx 1000ms ease infinite 0ms;
    }

    div:nth-child(2) {
      -webkit-animation: fx 1000ms ease infinite 300ms;
      animation: fx 1000ms ease infinite 300ms;
    }

    div:nth-child(3) {
      -webkit-animation: fx 1000ms ease infinite 600ms;
      animation: fx 1000ms ease infinite 600ms;
    }

    @-webkit-keyframes fx {
      50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    @keyframes fx {
      50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  }
}

#create-post-modal,
#promote-pitch-modal,
#create-pitch-modal,
#create-company-modal,
#edit-profile-modal,
#getting-started-modal,
.form-modal {
  .modal-dialog {
    transition: none;
  }

  .modal-dialog.modal-dialog-centered {
    @media (min-width: 2500px) {
      max-width: 600px;
    }

    @media (max-width: 1940px) {
      max-width: 600px;
    }

    @media (max-width: 1280px) {
      max-width: 600px;
    }

    @media (max-width: 600px) {
      max-width: 480px;
    }

    @media (max-width: 420px) {
      max-width: 100vw;
    }
  }

  #alert-message {
    margin-top: 10px;
  }

  .modal-body {
    padding: 20px 20px 20px 20px;

    @media (min-width: 1200px) {
      padding: 20px 40px;
    }
  }

  .modal-body:has(.dynamic-choose-account-type) {
    padding: 20px 6px 20px 6px;
  }

  .create-post-pitch-form,
  .form-modal {
    form {
      display: flex;
      position: relative;
      flex-flow: column;
      min-height: 300px;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
      width: 100%;

      &>div {
        width: 100%;
      }

      .footnote {
        position: absolute;
        bottom: -15px;
        left: 0px;
      }
    }
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #2b2b2b;
    padding: 20px 20px 10px 40px;
    display: flex;
    justify-content: center;

    p {
      font-size: 22px;
    }

    svg {
      position: absolute;
      top: 25px;
      right: 25px;
      width: 24px;
      height: 24px;
    }
  }

  .modal-footer {
    border-top: 1px solid #2b2b2b;
    margin: 20px -20px 0px -20px;
    padding: 10px 0px 0px 0px;

    button {
      margin: 0px auto;
      font-size: 15px;
      padding: 7px 10px;
      text-transform: none;
      flex-grow: 0;
      white-space: nowrap;
      max-height: 40px;
      max-width: 45%;
      min-width: 30%;

      &.alone {
        max-width: unset;
      }
    }

    .is-highlight,
    .is-grayed {
      display: flex;

      button {
        max-width: unset !important;
        width: auto !important;
        padding: 7px 20px !important;
      }
    }

    @media (max-width: 600px) {
      position: sticky;
      z-index: 1000;
      bottom: 0px;
      left: 0;
      right: 0;
      background: black;
      top: unset;
    }
  }

  .modal-content {
    background-color: #171717;
    background: #171717;

    .content {
      display: flex;
      flex-flow: column;
    }
  }

  .subtitle {
    font-family: 'MuliBold';
    text-align: center;
    color: #eee;
  }

  h4 {
    font-size: 16px;
  }

  .isPitchVideo,
  .isPayment {
    padding: 0px;
    margin: 0px;

    @media (min-width: 1200px) {}

    .modal-footer {
      .row {
        justify-content: space-between !important;
        max-height: 40px;
      }

      button {
        width: 30%;
        flex-grow: 0;
        max-height: 40px;
      }

      .step-back {
        cursor: pointer;
        width: 30%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
          margin-left: 20px;
          width: 24px;
          max-height: 24px;
          fill: white;
        }
      }

      .step-indicator {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          width: 10px;
          height: 10px;
          margin: 5px;
          border-radius: 5px;
          background-color: #444444;

          &.active {
            background-color: $radivision-blue;
          }
        }
      }
    }
  }

  table.payment-plans {
    border-collapse: collapse;
    border: 0;

    td,
    th {
      border: 0;
    }

    thead {
      tr {
        th {
          padding: 15px 0px 0px 0px;

          div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }

          h6,
          h3 {
            margin-top: 0px;
            padding-top: 0px;
            text-align: left;
            white-space: wrap;
            max-width: min-content;
            font-size: 18px;
            font-family: MuliBold;
            color: $radivision-blue;

            @media (min-width: 1900px) {
              font-size: 22px;
            }

            @media (min-width: 1200px) {
              font-size: 20px;
            }
          }

          &.selected {
            background-color: $radivision-blue;
            color: #ffffff;
            border-radius: 20px 20px 0px 0px;

            h3 {
              color: #ffffff;
            }
          }
        }
      }
    }

    tbody {
      p {
        color: white;
        font-size: 16px;

        span {
          color: $radivision-blue;
          margin-right: 2px;
        }
      }

      tr {
        border-top: 1px solid #666;

        &.select-buttons {
          border: 0px;
        }

        &:first-child {
          border-top: 0px;
        }

        td {
          padding: 10px 5px;

          &.selected {
            background-color: $radivision-blue;
            color: #ffffff;

            &.tier {
              h6 {
                color: #ffffff !important;
              }
            }
          }

          &.benefit-title,
          &.benefit-title p {
            font-size: 16px;
            line-height: 20px;
            text-align: left;
            font-family: Muli;
            color: #ffffff;
          }

          &.tier {
            vertical-align: top;

            &.pricing {
              &>div {
                display: flex;
                flex: 1;
                flex-flow: column;
                justify-content: flex-start;
                align-items: center;

                h4 {
                  font-size: 20px;
                  font-family: Muli;
                  color: #ffffff;
                  white-space: nowrap;
                }

                h6 {
                  font-size: 16px;
                  color: #cccccc;
                  font-family: Muli;
                  text-align: center;
                }
                h6:nth-child(2) {
                  color: $radivision-blue;
                }
              }
            }

            &.benefit-title {
              padding: 10px 0px 0px 5px;
              font-size: 20px;
              text-align: left;
              font-family: MuliBold;

              span {
                font-size: 14px;
              }
            }
          }

          &.title {
            width: 25%;
          }

          &.benefit-checkbox {
            width: 18%;
            text-align: center;

            svg {
              width: 25px;
              fill: $radivision-blue;
            }

            &.selected {
              svg {
                fill: #ffffff;
              }
            }

            .checkbox-control {
              justify-content: center;
              align-items: center;
            }
          }

          &.plan-select {
            vertical-align: bottom;

            &.selected {
              background-color: $radivision-blue;
              color: #ffffff;
              border-radius: 0px 0px 20px 20px;

              h5.select {
                display: none;
              }

              h5.current {
                color: #fff;
              }
            }
          }

          h5.current,
          h5.select {
            cursor: pointer;
            margin: 0px auto;
            padding: 6px 5px;
            max-width: 100px;
            font-size: 16px;

            &.select {
              border: 1px solid $radivision-blue;
              color: $radivision-blue;
              border-radius: 20px;

              &:hover {
                border: 1px solid transparent;
                background-color: $radivision-blue;
                font-family: MuliBold;
                color: #ffffff;
              }
            }
          }
        }
      }

      .foot-note {
        border: 0px;

        td {
          text-align: left;

          span {
            color: $radivision-blue;
          }
        }
      }
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  #create-post-form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  .modal-footer .row {
    justify-content: center;
  }

  .editable {
    color: #f0f0f0;
    min-height: 80px;
    margin-top: 15px;
    outline: 0px solid transparent;
    width: 100%;
    background-color: #171717;
    border: 0;
    resize: none;
    font-size: 18px;
  }

  .editable:focus~.placeholder {
    display: none;
  }

  .submit-post-button.disabled {
    background-color: #777777;
    background-image: none;
    color: #dddddd;
  }

  .chars-counter {
    display: flex;
    justify-content: flex-end;
    color: #777777;
    font-size: 13px;
    padding-right: 25px;

    &.limit-exceeded {
      color: #d00;
    }
  }

  .write-something {
    position: relative;
    min-height: 100px;

    .placeholder {
      pointer-events: none;
      color: #585858;
      font-size: 20px;
      position: absolute;
      top: 15px;
      left: 0px;
    }
  }

  .upload-image-bar {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(md) {
      flex-flow: column nowrap;
    }

    .image-preview {
      flex-grow: 1;
      margin-bottom: 20px;
      width: 100%;

      .upload-to-see-preview,
      .upload-file-note {
        font-size: 14px;
        color: #999999;
        font-family: Muli;
      }

      .label {
        margin-top: 20px;
        font-size: 15px;
        font-weight: 700;
        color: white;
        font-family: Muli;
      }
    }

    .image-container {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 140px;
      height: 140px;
      border-radius: 70px;
      background-color: #2e2e2e;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        transform: scale(2);
        opacity: 0.7;
      }
    }

    .upload-button {
      position: relative;
      cursor: pointer;
      border: 1px solid $radivision-blue;
      border-radius: 20px;
      margin: 10px 0px;
      box-sizing: border-box;
      flex-grow: 0;
      height: 36px;
      width: 140px;
      overflow: hidden;

      &:hover {
        background-color: $radivision-blue;
        color: white;

        .button {
          span {
            color: white;
          }
        }
      }

      .button {
        pointer-events: none;
        cursor: pointer;
        position: absolute;
        display: flex;
        flex-flow: column nowrap;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 1;
        justify-content: center;
        align-items: center;

        span {
          margin: 5px 10px;
          color: $radivision-blue;
          font-size: 13px;
        }
      }

      input {
        width: 100%;
        opacity: 0;
        cursor: pointer !important;
        height: 36px;
        flex: 1;
      }
    }
  }

  .upload-image-file {
    display: flex;
    justify-content: center;
    align-items: center;

    .upload-button {
      position: relative;
      border: 1px solid #202020;
      border-radius: 20px;
      margin: 10px 0px;
      box-sizing: border-box;
      width: 50%;
      min-height: 150px;
      max-width: 320px;

      .button {
        pointer-events: none;
        position: absolute;
        display: flex;
        flex-flow: column nowrap;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        justify-content: center;
        align-items: center;

        span {
          margin: 5px 10px;
          color: #fcfcfc;
        }

        .image-icon {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #2b2b2b;

          svg {
            fill: rgba(231, 76, 103, 1);
            width: 40px;
            transform: rotate(-3deg);
          }
        }
      }

      input {
        opacity: 0;
        width: 100%;
        height: 150px;
        flex: 1;
      }
    }
  }

  .modal-subheader {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: -10px;
  }

  .toggle-tags {
    display: flex;
    align-items: center;

    a {
      font-size: 16px;
      color: $radivision-blue;
      display: flex;
      padding: 10px 0px 10px 10px;
    }
  }
}

.tags-list {
  box-sizing: border-box;
  transition: max-height 0.2s ease-in;
  overflow: hidden;

  label {
    color: #aaaaaa;
  }

  .react-tag-input {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: auto;
    min-height: 2.375em;
    padding: 0.1875em 0.375em;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1rem;
    background: #2b2b2b;
    color: #333;
    border: 0px solid #e1e1e1;
    border-radius: 3px;
  }

  .react-tag-input * {
    box-sizing: border-box;
  }

  .react-tag-input>* {
    margin: 0.1875em;
  }

  .react-tag-input__input {
    width: auto;
    flex-grow: 1;
    height: 1.875em;
    padding: 0 0 0 0.1875em;
    margin: 0 0.1875em;
    font-size: 1em;
    line-height: 1;
    background: transparent;
    color: #fcfcfc;
    border: none;
    border-radius: 3px;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: none;
  }

  .react-tag-input__input::placeholder,
  .react-tag-input__input:-moz-placeholder,
  .react-tag-input__input:-ms-input-placeholder,
  .react-tag-input__input::-moz-placeholder,
  .react-tag-input__input::-webkit-input-placeholder {
    color: #fcfcfc;
  }

  .react-tag-input__input:focus {
    border: none;
  }

  .react-tag-input__tag {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 0.85em;
    line-height: 1;
    background: #aaaaaa;
    border-radius: 3px;
  }

  .react-tag-input__tag__content {
    outline: 0;
    border: none;
    white-space: nowrap;
    padding: 0 0.46875em;
    color: #141414;
  }

  .react-tag-input__tag__remove {
    position: relative;
    height: 2em;
    width: 2em;
    font-size: 0.85em;
    cursor: pointer;
    background: #d4d4d4;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .react-tag-input__tag__remove:before,
  .react-tag-input__tag__remove:after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: " ";
    height: 0.9em;
    width: 0.15em;
    background-color: #2b2b2b;
  }

  .react-tag-input__tag__remove:before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  .react-tag-input__tag__remove:after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }

  .react-tag-input__tag__remove-readonly {
    width: 0;
  }

  .react-tag-input__tag__remove-readonly:before,
  .react-tag-input__tag__remove-readonly:after {
    content: "";
    width: 0;
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    background: transparent;
    height: 4px;
    margin-left: 30px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #222222;
    border-radius: 2px;
    width: 40px;
    height: 2px;
  }
}

.create-post-pitch-form,
.form-modal-container,
.form-modal {
  margin-top: 0px;

  .investment-url-tooltip {
    margin-top: 0px;
    margin-bottom: 20px;
    display: flex;
    flex-flow: row;
    align-items: center;

    p {
      text-align: left;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      color: #aaaaaa;
      fill: #aaaaaa;
    }
  }

  .btn-radivision {
    &.disabled {
      background-image: none;
      background-color: #888 !important;
    }

    &.alone {
      max-width: unset;
    }
  }

  .search-form.search-form-shown {
    top: 0px;
    position: relative;
    z-index: 1;
    left: unset;
    right: unset;
    width: 100%;

    .search-input-container input {
      font-size: 24px;
    }
  }

  .create-post-is-pitch {
    label {
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
      }
    }
  }

  .investment-row {
    display: flex;
    flex-flow: row nowrap;

    @include media-breakpoint-down(md) {
      flex-flow: row wrap;
      width: 100%;
    }
  }

  .form-section {
    margin: 20px 0px;
    padding: 10px 0px;

    .form-section-title {
      font-family: MuliBold;
      font-size: 18px;
    }
  }

  .create-post-investment-url,
  .form-field-container {
    display: flex;
    margin: 0px 5px;
    flex-flow: column;
    flex-grow: 1;

    @include media-breakpoint-down(md) {
      width: 100%;
      flex-basis: 100%;
    }

    a {
      color: $radivision-blue !important;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .error-message {
      color: #d00;
    }

    label {
      margin: 10px 0px 0px 0px;
      padding: 0px;
      font-size: 15px;
      display: flex;
      align-items: center;
      font-weight: 700;
      color: white;
      font-family: Muli;
      font-weight: normal;

      span.label-text {
        display: flex;
        align-items: center;
      }
    }

    .field-help-text {
      text-align: left;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .investment-url-input,
    .form-field-input {
      position: relative;

      .is-grey {
        color: #555555;
      }

      .is-grey:focus {
        color: #ffffff;
      }

      span {
        position: absolute;
        top: 8px;
        left: 18px;
        z-index: 1;

        svg {
          fill: $radivision-blue;
          width: 18px;
          height: 18px;
        }
      }

      select {
        background-color: #2e2e2e;
        border: 0;
        color: #fff;
        font-size: 16px;
        height: 43px;
        border-radius: 20px;
        width: 100%;
        padding: 0px 24px;
        -ms-word-break: normal;
        word-break: normal;

        &:focus,
        &:active {
          background-color: #2e2e2e;
          color: #fff;
        }

        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: #2e2e2e;
        background-image: url('data:image/svg+xml;utf8,<svg width="23.999999999999996" height="23.999999999999996" xmlns="http://www.w3.org/2000/svg"><g><title>Layer 1</title><path fill="white" stroke="null" d="m12.35714,15.36905l-5.97619,-7.60715l11.95238,0l-5.97619,7.60715l0.00001,0z" id="svg_1"/></g></svg>');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 5px);
        background-position-y: 12px;
      }

      input {
        border: 1px solid #2e2e2e;
        background-color: #2e2e2e;
        padding: 0px 7px 0px 22px;
        width: 100%;
        border-radius: 22px;
        font-family: Muli;
        font-size: 14px;
        color: #fff;
        height: 43px;
        position: relative;
      }

      textarea {
        border: 1px solid #2e2e2e;
        background-color: #2e2e2e;
        padding-left: 22px;
        width: 100%;
        border-radius: 22px;
        font-family: Muli;
        font-size: 16px;
        color: #fff;
        position: relative;
      }

      .checkbox-control {
        label {
          margin-top: 0px;
        }

        margin: 5px 0px;
      }
    }
  }
}

#create-pitch-modal,
#promote-pitch-modal {
  .modal-content {
    background-color: rgba(23, 23, 23, 1);
    //background-image: url('../ts/react-components/panels/pitch-panel/background.jpg');
    background-position: center center;
    background-size: auto 90vh;
    background-repeat: no-repeat;
    position: relative;

    &::before {
      content: " ";
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      background-color: rgba(0, 0, 0, 0.85);
    }
  }

  table.payment-plans {
    transform: translateY(-20px);
  }

  .modal-body {
    overflow: visible;
  }

  .modal-header {
    border: 0px;

    p {
      transform: translateY(50px);
    }
  }

  .modal-footer {
    .row {
      width: 100%;
    }
  }

  .modal-body::before {
    content: " ";
    position: absolute;
    top: 0px;
    left: 40px;
    transform: translateY(-40px);
    height: 120px;
    width: 120px;
    //background-image: url('../static/pitch_planet_on_black.png');
    background-image: url("../static/pp-square.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    margin-left: 5px;
    border-radius: 10px;
    overflow: hidden;
  }

  .content {
    margin-top: 70px;
  }

  form>p {
    text-align: left;
    width: 100%;
    padding: 20px 0px !important;
  }

  #stripe-checkout {
    margin-top: 20px !important;

    img {
      margin: 20px 10px;
    }
  }

  .modal-dialog {
    margin: auto;
  }
}

#create-pitch-modal {
  .add-media-modal {
    @media (min-width: 1200px) {
      max-width: 650px !important;

      &.step-STEP_CREATE_PITCH_SELECT_PLAN,
      &.step-STEP_CREATE_PITCH_CHECKOUT,
      &.step-STEP_CREATE_PITCH_SUMMARY {
        max-width: 900px !important;
      }

      .modal-header p {
        transform: translate(30px, 50px);
      }
    }

    @media (max-width: 600px) {
      max-width: 100vw !important;

      .modal-body {
        .content {
          margin-top: 20px;
        }
      }

      .modal-header {
        padding: 20px 20px 10px 20px;

        p {
          transform: translate(0px, 50px);
        }
      }

      .modal-body::before {
        display: none;
      }
    }
  }
}

#promote-pitch-modal {
  .add-media-modal {
    @media (min-width: 1200px) {
      max-width: 900px !important;
    }
  }
}

.payment-plan-card {
  margin-bottom: 30px;

  .payment-plan-header {
    border-bottom: 1px solid $radivision-blue;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    h1 {
      color: $radivision-blue;
      font-size: 22px;
    }

    h3 {
      color: $radivision-blue;
      font-size: 18px;
    }

    .select-custom-amount,
    .enter-custom-amount {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      span {
        font-size: 18px;
        margin-left: 10px;
        color: $radivision-blue;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
      }

      input.custom-amount {
        border: 0;
        width: 100px !important;
        color: #fff;
        font-size: 18px;
        height: 36px;
        border-radius: 18px;
        width: 150px;
        padding: 0px 24px;
        -ms-word-break: normal;
        word-break: normal;
        font-family: Muli !important;
        margin: 0px !important;
      }

      select {
        border: 0;
        color: #fff;
        font-size: 18px;
        height: 36px;
        border-radius: 18px;
        width: 150px;
        padding: 0px 24px;
        -ms-word-break: normal;
        word-break: normal;

        &:focus,
        &:active {
          background-color: #2e2e2e;
          color: #fff;
        }

        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: #2e2e2e;
        background-image: url('data:image/svg+xml;utf8,<svg width="23.999999999999996" height="23.999999999999996" xmlns="http://www.w3.org/2000/svg"><g><title>Layer 1</title><path fill="white" stroke="null" d="m12.35714,15.36905l-5.97619,-7.60715l11.95238,0l-5.97619,7.60715l0.00001,0z" id="svg_1"/></g></svg>');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 10px);
        background-position-y: 8px;
      }
    }
  }

  .payment-plan-footer {
    padding-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid $radivision-blue;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    p.started-at {
      font-size: 13px;
      color: #eeeeee;
      margin: 0px;
      padding: 0px;
    }

    h4 {
      color: white;
      font-size: 22px;

      small {
        color: white;
        font-size: 14px;
      }

      &.highlight {
        color: #ffffff;
        font-size: 16px;
        line-height: 24px;
      }
    }

    h4.total-amount {
      text-align: right !important;

      div {
        white-space: nowrap;
        margin-left: 30px;
      }

      .is-discounted {
        text-decoration: line-through;
        color: #aaaaaa;
      }
    }
  }

  .payment-plan-benefits {
    padding: 10px 10px;
    display: flex;
    flex-flow: column nowrap;

    .plan-benefit {
      display: flex;
      flex-flow: row nowrap;
      margin: 10px 0px;

      h4 {
        p {
          margin: 0px;
          font-size: 16px;
          color: white;
          text-align: left;
        }
      }

      svg {
        flex-shrink: 0;
        margin: 0px 20px 0px 0px;
        width: 24px;
        max-height: 24px;
        fill: $radivision-blue;
      }
    }
  }

  @media (max-width: 500px) {
    .payment-plan-header {
      flex-wrap: wrap;

      h3 {
        flex-wrap: wrap;

        .enter-custom-amount {
          span {
            font-size: 15px;
            white-space: nowrap;
            margin-left: 0px;
          }
        }
      }
    }

    h4.total-amount {
      width: 100%;
      font-size: 20px !important;
    }

    .payment-plan-footer {
      flex-wrap: wrap;
    }
  }
}

#create-pitch-modal {
  .step-0 {
    h1 {
      font-size: 22px;
      font-family: MuliBold;

      em {
        color: $radivision-blue;
        font-style: normal;
      }
    }

    h3 {
      font-size: 17px;
      font-family: MuliSemiBold;
    }

    h2 {
      margin: 30px 0px 20px 0px;
      font-size: 22px;
      font-family: MuliBold;
      text-align: center;

      em {
        color: $radivision-blue;
        font-style: normal;
      }
    }

    ol {
      margin: 0px 0px 0px 0px;
      padding: 0px;

      li {
        margin: 0px;
        padding: 0px;
        list-style: none;
        font-size: 17px;

        b {
          color: $radivision-blue;
        }
      }
    }

    ul {
      margin: 0px 0px 0px 20px;
      padding: 0px;

      li {
        margin: 0px;
        padding: 0px;
        list-style: disc;
        font-size: 16px;
        color: $radivision-blue;

        span {
          color: #ffffff !important;
        }

        b {
          color: $radivision-blue;
        }
      }
    }
  }
}

.promotion-terms-modal {
  .modal-body {

    p,
    h5 {
      text-align: left;
    }

    p {
      margin: 20px 0px;
      font-size: 16px;
    }

    a {
      color: $radivision-blue;
    }
  }
}

.or-line-break {
  margin: 10px 0px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .br {
    border-bottom: 1px solid #313131;
    flex-grow: 1;
    flex: 1;
    height: 1px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 30px;
    height: 32px;

    em {
      font-size: 16px;
      font-family: MuliSemiBold;
      font-style: normal;
      color: #aaa;
    }
  }
}

#authentication-modal {
  .modal-dialog.modal-dialog-centered {
    @media (min-width: 2500px) {
      max-width: 480px;
    }

    @media (max-width: 1940px) {
      max-width: 480px;
    }

    @media (max-width: 1280px) {
      max-width: 480px;
    }

    @media (max-width: 600px) {
      max-width: 480px;
    }

    @media (max-width: 420px) {
      max-width: 100vw;
    }
  }
}

#authentication-modal,
#create-company-modal,
#edit-profile-modal {
  .forgot-password-link {
    button {
      border: 0px;
      color: $radivision-blue;
      font-size: 16px;
      padding: 0px 10px;
      margin-bottom: 20px;
      background-color: transparent;
    }
  }

  .onboarding-investor {
    .checkbox-control {
      .checkbox-sign {
        background-color: #2e2e2e;
        border-radius: 12px;
        width: 24px;
        height: 24px;
      }
    }
  }

  .dont-have-an-account {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    font-size: 16px;

    a {
      color: $radivision-blue;
      cursor: pointer;
    }
  }

  .btn-radivision {
    font-size: 16px;
    padding: 8px 20px;
    flex-grow: 1;
    width: 100%;

    &.alone {
      max-width: unset;
    }
  }

  .form-field-container {
    margin: 20px 0px 10px 0px;

    .form-field-input {
      border: 1px solid #2e2e2e;
      background-color: #2e2e2e;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      width: 100%;
      border-radius: 22px;
      font-family: Muli;
      font-size: 16px;
      color: #fff;
      min-height: 48px;
      position: relative;
      flex-flow: column nowrap;

      input {
        font-size: 16px;
      }

      span {
        left: 0px;
        top: 0px;
        transform: scale(0.8);
        width: 60px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          fill: #ffffff;
          width: 24px;
          height: 24px;
          opacity: 0.6;
        }
      }

      &.input-with-icon {
        &.right-aligned {
          span {
            right: 0px;
            left: unset;

            svg {
              fill: #ffffff;
            }
          }
        }
      }

      &.input-with-reset {
        padding-right: 30px;

        &.right-aligned {
          span:not(.remove) {
            right: 40px;
            left: unset;

            svg {
              fill: #ffffff;
            }
          }
        }
      }

      .chars-counter {}
    }

    span.remove {
      right: 0px;
      left: unset;

      svg {
        fill: #ffffff;
      }
    }

    input {
      &::placeholder {
        color: #666666;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    max-width: 100vw;

    .modal-header {
      padding: 0px;
      margin: 0px;
      position: relative;
      border: 0px;

      &>p {
        margin-top: 50px;
        font-family: MuliExtraBold;
        font-size: 24px;
      }

      button.close {

        span,
        i {
          display: flex;
          width: 40px;
          height: 40px;
        }

        float: unset;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;

        svg {
          width: 20px;
          height: 20px;
          top: 0px;
          left: 0px;
          right: 0px;
          position: unset;
        }
      }
    }

    .modal-content {
      border-radius: 0px;
      min-height: 100vh;

      .subtitle {
        margin-bottom: 20px;
      }
    }

    .modal-dialog {
      margin: 0px;
    }

    .modal-footer {
      border: 0px;

      .row {
        margin: 0px 20px;
      }
    }

    .modal-body {
      padding-top: 0px;
    }

    .btn-radivision {
      font-size: 16px;
      flex-grow: 1;
      margin-top: 10px;
      max-width: 100vw;
      max-height: 48px;
      height: 48px;
      width: 100%;

      &.disabled {
        background-image: none;
        background-color: #888 !important;
      }

      &.top-mc {
        margin-top: 4.5rem
      }

      &.alone {
        max-width: unset;
      }
    }

    .signin-form {
      max-width: 360px;
      position: relative;
      margin: 0 auto;

      .subtitle {
        color: #aaa;
      }
    }
  }
}

.verification-code-form {
  .form-field-container {
    &>div {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 0px 10px;

      .otp-input {
        margin: 5px;
        flex-grow: 1;
        display: flex;
        border-radius: 0px;

        input {
          flex-grow: 1;
          border-radius: 0px;
          border-width: 0px 0px 1px 0px;
          border-bottom: 1px solid #888;
          background-color: transparent;
          font-size: 36px;
          font-family: Muli;
          padding: 0px 0px 10px 0px;
          color: #ffffff;
        }
      }
    }
  }
}

.validations-rules {
  ul {
    padding: 0px;
    margin: 0px;

    li {
      padding: 0px;
      margin: 10px 0px;
      color: #888;
      font-size: 16px;
      list-style: none;
      display: flex;
      align-items: center;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;

        svg {
          fill: #888;
          width: 18px;
          height: 18px;
        }
      }

      &.valid-rule {
        color: #fff;

        svg {
          fill: #fff;
        }
      }
    }
  }
}

.dynamic-choose-account-type {
  min-height: 600px;
  z-index: 10;
  margin-top: 10px;

  &.greyscale {
    filter: grayscale(100%);
  }
}

.choose-account-type {
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  .account-types {
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    height: 60vh;
    padding: 0px;
    margin: 0px;
    margin-top: 2rem;


    .account-type-card {
      list-style: none;
      flex: 1;
      flex-grow: 0;
      height: 150px;
      width: 100%;
      box-sizing: border-box;
      padding: 0px 20px;
      margin: 10px 0px;
      border: 1px solid #fff;
      border-radius: 20px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      background-color: #fff;
      cursor: pointer;

      h3 {
        font-size: 20px;
        font-family: MuliBold;
        color: black;
        margin: 0px;
        align-content: center;
        text-align: center;
      }

      p {
        opacity: 0.7;
        margin-top: 5px;
        padding: 0px;
        font-size: 14px;
        font-family: MuliBold;
        line-height: 18px;
        color: black;
        text-align: left;
      }

      &.is-active {
        border: 1px;

        p,
        h3 {
          color: white;
        }
      }
    }
  }
}

.providers-signin {
  .social-login-btn {
    cursor: pointer;
    height: 48px;
    margin: 15px 0px;
    border: 1px solid #313131;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      color: white;

      .icon {
        display: flex;
        flex-basis: 60px;
        margin-left: 10px;
        justify-content: center;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      span {
        border-left: 1px solid #313131;
        flex-grow: 1;
        text-align: center;
        font-family: Muli;
        font-size: 16px;
      }
    }

    &.linkedinIcon {
      svg {
        fill: #0e76a8;
      }
    }
  }
}

.resend-code-link {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    cursor: pointer;
    border: 0px;
    padding: 0px 10px;
    margin-bottom: 20px;
    background-color: transparent;
    text-decoration: underline !important;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: #aaa;
    font-size: 16px;

    .icon {
      width: 20px;
      height: 20px;
      max-height: 18px;
      min-width: 20px;
      margin-right: 5px;
      flex-grow: 0;

      svg {
        width: 18px;
        height: 18px;
        max-height: 18px;
        fill: #aaa !important;
        stroke: #aaa !important;
      }
    }

    span {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

ul.add-social-links {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;

  li {
    list-style-type: none;
    padding-right: 1rem;
    display: flex;
    align-items: center;

    &:last-child {
      padding-right: 0;
    }

    a {
      height: 17px;
      display: flex;
      align-items: center;
    }

    svg {
      fill: white;
      width: 17px;
      height: 17px;

      @media (min-width: 2500px) {
        width: 23px;
        height: 23px;
        margin-top: 5px;
      }
    }

    &.twitter-icon {
      svg {
        padding-top: 2px;
      }
    }

    .active {
      svg {
        fill: white;
        cursor: pointer;
      }
    }

    &>svg {
      opacity: 0.4;
    }

    &:empty {
      padding-right: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    margin-top: 10px;
  }
}

#add-social-platform {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  min-height: 40px;
  margin: 0px 0px 10px 10px;

  a.select-platform {
    cursor: pointer;
  }

  a {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #212121;
    display: flex;
    margin: 0px 10px 10px 0px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      svg {
        width: 22px;
        height: 22px;
        fill: #888888;
      }
    }
  }
}

#edit-profile-modal {
  .edit-profile-form {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    .form-body {
      flex-grow: 1;
      height: 100%;
      width: 100%;
    }
  }

  .modal-footer {
    width: 100%;
    margin: 20px 0px 0px 0px;

    .row {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  @include media-breakpoint-down(md) {
    .form-sections {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    .form-modal {
      .modal-content {
        min-height: 100vh;
      }
    }

    .form-sections {
      list-style: none;
      position: sticky;
      top: 0px;
      padding: 0px;
      margin: 0px;

      li {
        padding: 0px;
        display: block;
        margin: 5px 0px;
        font-size: 16px;
        cursor: pointer;
        color: #ccc;

        &.active {
          font-family: MuliBold;
          color: white;
        }

        &:hover {}
      }
    }
  }
}

#cancel-promotion-modal {
  .modal-header {
    p {
      font-size: 26px;
    }
  }
}